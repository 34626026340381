var mappings = [
  {
    "id": "614",
    "category": "Medical Spa"
  },
  {
    "id": "1545593",
    "category": "Lima Memorial Health System"
  },
  {
    "id": "1277759",
    "category": "Corporate Office"
  },
  {
    "id": "2090",
    "category": "Medical School"
  },
  {
    "id": "1135249",
    "category": "Nursing School"
  },
  {
    "id": "120",
    "category": "Learning Center"
  },
  {
    "id": "1034",
    "category": "Non-Profit Organization"
  },
  {
    "id": "1352945",
    "category": "Assisted Living Facility"
  },
  {
    "id": "1037",
    "category": "Nursing Home"
  },
  {
    "id": "1038",
    "category": "Retirement Community"
  },
  {
    "id": "1325555",
    "category": "Birth Center"
  },
  {
    "id": "1042",
    "category": "Family Planning Center"
  },
  {
    "id": "1043",
    "category": "Genealogist"
  },
  {
    "id": "1044",
    "category": "Midwife"
  },
  {
    "id": "1325554",
    "category": "Family Counselor"
  },
  {
    "id": "131",
    "category": "Social Work"
  },
  {
    "id": "1077",
    "category": "Suicide Prevention Service"
  },
  {
    "id": "163",
    "category": "Alternative Medicine"
  },
  {
    "id": "1235",
    "category": "Acupuncture"
  },
  {
    "id": "1236",
    "category": "Biofeedback Therapy"
  },
  {
    "id": "1238",
    "category": "Deepak Chopra Center"
  },
  {
    "id": "1239",
    "category": "Herbal Medicine"
  },
  {
    "id": "1240",
    "category": "Holistic Medicine"
  },
  {
    "id": "1241",
    "category": "Homeopathy"
  },
  {
    "id": "1242",
    "category": "Hypnotherapy"
  },
  {
    "id": "2431",
    "category": "Medical Marijuana Dispensary"
  },
  {
    "id": "2180",
    "category": "Music Therapy"
  },
  {
    "id": "1243",
    "category": "Naturopathic Medicine"
  },
  {
    "id": "1244",
    "category": "Osteopathic Medicine"
  },
  {
    "id": "1245",
    "category": "Pain Management"
  },
  {
    "id": "2184",
    "category": "Reflexology"
  },
  {
    "id": "1246",
    "category": "Cosmetic Dentistry"
  },
  {
    "id": "1359940",
    "category": "Dental Hygienist"
  },
  {
    "id": "1247",
    "category": "Dental Implants Periodontistry"
  },
  {
    "id": "1248",
    "category": "Dental Laboratory"
  },
  {
    "id": "1059495",
    "category": "Dental Technician"
  },
  {
    "id": "1437822",
    "category": "Dentist"
  },
  {
    "id": "1250",
    "category": "Dentistry"
  },
  {
    "id": "1249",
    "category": "Denture Specialist"
  },
  {
    "id": "2145",
    "category": "Endodontics"
  },
  {
    "id": "1251",
    "category": "Oral Surgery"
  },
  {
    "id": "1252",
    "category": "Orthodontics"
  },
  {
    "id": "2144",
    "category": "Pediatric Dentistry"
  },
  {
    "id": "2147",
    "category": "Periodontics"
  },
  {
    "id": "2146",
    "category": "Prosthodontics"
  },
  {
    "id": "1253",
    "category": "Teeth Whitening Service"
  },
  {
    "id": "1324880",
    "category": "Doctor"
  },
  {
    "id": "1135560",
    "category": "Family Practice Physician"
  },
  {
    "id": "1255",
    "category": "General Practitioner"
  },
  {
    "id": "2177",
    "category": "Geriatrician"
  },
  {
    "id": "1120591",
    "category": "Hospitalist"
  },
  {
    "id": "2159",
    "category": "Nurse Practitioner"
  },
  {
    "id": "1257",
    "category": "Pediatrician"
  },
  {
    "id": "1258",
    "category": "Physician Assistant"
  },
  {
    "id": "1357511",
    "category": "Cycling Studio"
  },
  {
    "id": "1261",
    "category": "Exercise Equipment Store"
  },
  {
    "id": "1259",
    "category": "Fitness Center"
  },
  {
    "id": "1260",
    "category": "Fitness Classes"
  },
  {
    "id": "1359943",
    "category": "Gym"
  },
  {
    "id": "1262",
    "category": "Personal Trainer"
  },
  {
    "id": "2279",
    "category": "Pilates Studio"
  },
  {
    "id": "2280",
    "category": "Weight Training Center"
  },
  {
    "id": "2118",
    "category": "Yoga Studio"
  },
  {
    "id": "1437685",
    "category": "Contact Lenses Supplier"
  },
  {
    "id": "1264",
    "category": "Corrective Lense Store"
  },
  {
    "id": "168",
    "category": "Eye Care Center"
  },
  {
    "id": "1263",
    "category": "Eyeglass Repair"
  },
  {
    "id": "1265",
    "category": "Laser Vision Surgery"
  },
  {
    "id": "1266",
    "category": "Ophthalmology"
  },
  {
    "id": "1234639",
    "category": "Optician"
  },
  {
    "id": "1267",
    "category": "Optometry"
  },
  {
    "id": "1268",
    "category": "Protective Eyewear"
  },
  {
    "id": "1269",
    "category": "Home Health Care Service"
  },
  {
    "id": "1270",
    "category": "Home Nurses"
  },
  {
    "id": "1271",
    "category": "Hospice & Long Term Care"
  },
  {
    "id": "171",
    "category": " Medical Advice"
  },
  {
    "id": "1341",
    "category": "Dental Supply Store"
  },
  {
    "id": "1278",
    "category": "Diabetes Equipment Store"
  },
  {
    "id": "1279",
    "category": "Disability Resources"
  },
  {
    "id": "1280",
    "category": "First Aid Supplies"
  },
  {
    "id": "1281",
    "category": "Hearing Aid Store"
  },
  {
    "id": "1282",
    "category": "Home Medical Equipment Store"
  },
  {
    "id": "1285",
    "category": "Massage Supply Store"
  },
  {
    "id": "1286",
    "category": "Medical Alert System Store"
  },
  {
    "id": "1287",
    "category": "Medical Identification Products"
  },
  {
    "id": "1288",
    "category": "Medical Supplies"
  },
  {
    "id": "172",
    "category": "Medical Supply Store"
  },
  {
    "id": "1289",
    "category": "Mobility Equipment Store"
  },
  {
    "id": "1290",
    "category": "Mobility Scooters"
  },
  {
    "id": "1363",
    "category": "Orthotics"
  },
  {
    "id": "1291",
    "category": "Oxygen Equipment Store"
  },
  {
    "id": "1292",
    "category": "Prosthetics"
  },
  {
    "id": "1293",
    "category": "Sleep Apnea Supplies"
  },
  {
    "id": "1294",
    "category": "Walking Aids"
  },
  {
    "id": "1295",
    "category": "Wheelchair Rental Service"
  },
  {
    "id": "1296",
    "category": "Wheelchair Repair Service"
  },
  {
    "id": "1297",
    "category": "Wheelchairs"
  },
  {
    "id": "1298",
    "category": "Medical Billing"
  },
  {
    "id": "1299",
    "category": "Medical Business Administration"
  },
  {
    "id": "1300",
    "category": "Medical Records Services"
  },
  {
    "id": "1301",
    "category": "Medical Service Organization"
  },
  {
    "id": "1302",
    "category": "Medical Staffing"
  },
  {
    "id": "1303",
    "category": "Medical Transcription"
  },
  {
    "id": "1304",
    "category": "Physician Staffing"
  },
  {
    "id": "1305",
    "category": "Blood Bank"
  },
  {
    "id": "1306",
    "category": "Clinical Research"
  },
  {
    "id": "1307",
    "category": "Diagnostic Lab"
  },
  {
    "id": "1310",
    "category": "Dialysis Center"
  },
  {
    "id": "1308",
    "category": "Drug Testing Laboratory"
  },
  {
    "id": "1135539",
    "category": "Echocardiography"
  },
  {
    "id": "1309",
    "category": "Genetic Testing"
  },
  {
    "id": "1311",
    "category": "Medical Laboratory"
  },
  {
    "id": "1366245",
    "category": "Organ Donor Service"
  },
  {
    "id": "1313",
    "category": "STD Testing"
  },
  {
    "id": "1314",
    "category": "Stem Cell Preservation"
  },
  {
    "id": "1340",
    "category": "Cosmetic Medical Equipment"
  },
  {
    "id": "1342",
    "category": "Dental Equipment Leasing"
  },
  {
    "id": "1343",
    "category": "Hospital Equipment & Supplies"
  },
  {
    "id": "1344",
    "category": "Medical Equipment Leasing"
  },
  {
    "id": "1345",
    "category": "Medical Equipment Repair"
  },
  {
    "id": "1346",
    "category": "Pharmaceutical Equipment Leasing"
  },
  {
    "id": "1347",
    "category": "Surgical Appliances & Supplies"
  },
  {
    "id": "1272",
    "category": "Air Ambulance"
  },
  {
    "id": "1273",
    "category": "Ambulance Service"
  },
  {
    "id": "1502965",
    "category": "COVID-19 Testing Facility"
  },
  {
    "id": "1544225",
    "category": "COVID-19 Vaccine Location"
  },
  {
    "id": "1354",
    "category": "Cancer Treatment Center"
  },
  {
    "id": "1274",
    "category": "Children's Hospital"
  },
  {
    "id": "1135557",
    "category": "Diabetes Center"
  },
  {
    "id": "1135204",
    "category": "Emergency Room"
  },
  {
    "id": "128",
    "category": "Health Clinic"
  },
  {
    "id": "1276",
    "category": "Hospital"
  },
  {
    "id": "1256",
    "category": "Medical Clinic"
  },
  {
    "id": "2255",
    "category": "Mortuary"
  },
  {
    "id": "1277",
    "category": "Outpatient Services"
  },
  {
    "id": "1325",
    "category": "Psychiatric Hospital"
  },
  {
    "id": "1135555",
    "category": "Rehabilitation Center"
  },
  {
    "id": "1370",
    "category": "Surgery Center"
  },
  {
    "id": "1275",
    "category": "Urgent Care"
  },
  {
    "id": "1503536",
    "category": "Walk-In Clinic"
  },
  {
    "id": "169",
    "category": "Wellness Center"
  },
  {
    "id": "1135558",
    "category": "Women's Health Clinic"
  },
  {
    "id": "2150",
    "category": "Addiction Medicine"
  },
  {
    "id": "20000000",
    "category": "Adolescent Medicine"
  },
  {
    "id": "1505877",
    "category": "Advanced Laparoscopy"
  },
  {
    "id": "1505878",
    "category": "Aesthetic Plastic Surgery"
  },
  {
    "id": "1353",
    "category": "Allergies & Immunology"
  },
  {
    "id": "2151",
    "category": "Anesthesiology"
  },
  {
    "id": "2174",
    "category": "Audiology"
  },
  {
    "id": "1505879",
    "category": "Bariatric Surgery"
  },
  {
    "id": "20000001",
    "category": "Breast Imaging"
  },
  {
    "id": "20000002",
    "category": "Breast Medical Oncologist"
  },
  {
    "id": "1505880",
    "category": "Breast Medical Oncology"
  },
  {
    "id": "20000030",
    "category": "Breast Surgery"
  },
  {
    "id": "20000031",
    "category": "Cardiac Electrophysiology"
  },
  {
    "id": "1505881",
    "category": "Cardiologist"
  },
  {
    "id": "1355",
    "category": "Cardiology"
  },
  {
    "id": "1135537",
    "category": "Interventional Cardiology"
  },
  {
    "id": "1135540",
    "category": "Nuclear Cardiology"
  },
  {
    "id": "1505882",
    "category": "Cardiothoracic Surgery"
  },
  {
    "id": "20000003",
    "category": "Cardiovascular Surgery"
  },
  {
    "id": "1512875",
    "category": "Child Psychologist"
  },
  {
    "id": "1505883",
    "category": "Child and Adolescent Psychiatry"
  },
  {
    "id": "318",
    "category": "Chiropractor"
  },
  {
    "id": "1505884",
    "category": "Clinical Neuropsychology"
  },
  {
    "id": "2175",
    "category": "Clinical Pharmacology"
  },
  {
    "id": "2253",
    "category": "Colon & Rectal Surgery"
  },
  {
    "id": "1505885",
    "category": "Craniofacial Surgery"
  },
  {
    "id": "2176",
    "category": "Critical Care Medicine"
  },
  {
    "id": "1505886",
    "category": "Critical Care Surgery"
  },
  {
    "id": "1254",
    "category": "Dermatology"
  },
  {
    "id": "1135542",
    "category": "Cosmetic Dermatology"
  },
  {
    "id": "1135541",
    "category": "Dermatopathology"
  },
  {
    "id": "1505887",
    "category": "Dermatopathologist"
  },
  {
    "id": "1505888",
    "category": "Dietician"
  },
  {
    "id": "1356",
    "category": "Ear Nose & Throat"
  },
  {
    "id": "1505889",
    "category": "Echocardiology"
  },
  {
    "id": "20000004",
    "category": "Electrodiagnostic Medicine"
  },
  {
    "id": "1505890",
    "category": "Emergency Medicine"
  },
  {
    "id": "2152",
    "category": "Endocrinology"
  },
  {
    "id": "2164",
    "category": "Pediatric Endocrinology"
  },
  {
    "id": "2163",
    "category": "Reproductive Endocrinology"
  },
  {
    "id": "20000029",
    "category": "Facial Plastic Surgery"
  },
  {
    "id": "1505891",
    "category": "Family Medicine"
  },
  {
    "id": "1357",
    "category": "Gastroenterology"
  },
  {
    "id": "1505892",
    "category": "Gastrointestinal Medical Oncology"
  },
  {
    "id": "1505893",
    "category": "General Surgery"
  },
  {
    "id": "2153",
    "category": "Genetics"
  },
  {
    "id": "1505894",
    "category": "Genitourinary Medical Oncology"
  },
  {
    "id": "1505895",
    "category": "Geriatric Medicine"
  },
  {
    "id": "2154",
    "category": "Gerontology"
  },
  {
    "id": "20000005",
    "category": "Gynecologic Oncology"
  },
  {
    "id": "2178",
    "category": "Hand Surgery"
  },
  {
    "id": "1505896",
    "category": "Head and Neck Medical Oncology"
  },
  {
    "id": "1505897",
    "category": "Head and Neck Oncology"
  },
  {
    "id": "1505898",
    "category": "Head and Neck Plastic Surgery"
  },
  {
    "id": "2254",
    "category": "Hematology"
  },
  {
    "id": "1505899",
    "category": "Hematology & Oncology"
  },
  {
    "id": "20000006",
    "category": "Hematology-Oncology"
  },
  {
    "id": "1135543",
    "category": "Hepatology"
  },
  {
    "id": "1505900",
    "category": "Hospice and Palliative Medicine"
  },
  {
    "id": "2155",
    "category": "Infectious Disease Medicine"
  },
  {
    "id": "1505843",
    "category": "Infertility and Reproductive Disorders"
  },
  {
    "id": "20000032",
    "category": "Injury and Wound Care"
  },
  {
    "id": "1358",
    "category": "Internal Medicine"
  },
  {
    "id": "2156",
    "category": "Internist"
  },
  {
    "id": "20000007",
    "category": "Interventional Neuroradiology"
  },
  {
    "id": "20000008",
    "category": "Interventional Radiology"
  },
  {
    "id": "20000009",
    "category": "Intestinal Transplantation"
  },
  {
    "id": "20000010",
    "category": "Kidney / Pancreas Transplantation"
  },
  {
    "id": "1505901",
    "category": "Liver Surgery"
  },
  {
    "id": "20000011",
    "category": "Maternal and Fetal Medicine"
  },
  {
    "id": "1505902",
    "category": "Medical Bariatrician"
  },
  {
    "id": "2179",
    "category": "Medical Examiner"
  },
  {
    "id": "1505903",
    "category": "Neonatal - Perinatal Medicine"
  },
  {
    "id": "1505904",
    "category": "Neonatal Surgery"
  },
  {
    "id": "1120592",
    "category": "Neonatology"
  },
  {
    "id": "2157",
    "category": "Nephrology"
  },
  {
    "id": "1505905",
    "category": "Neuro-Oncology"
  },
  {
    "id": "20000012",
    "category": "Neuro-Ophthalmology"
  },
  {
    "id": "1359997",
    "category": "Neurologist"
  },
  {
    "id": "1360",
    "category": "Neurology"
  },
  {
    "id": "20000033",
    "category": "Neuropsychology"
  },
  {
    "id": "2158",
    "category": "Neurosurgery"
  },
  {
    "id": "1505906",
    "category": "Neurotology"
  },
  {
    "id": "2181",
    "category": "Nuclear Medicine"
  },
  {
    "id": "20000013",
    "category": "Obstetrics & Gynecology"
  },
  {
    "id": "20000014",
    "category": "Ocular Oncology"
  },
  {
    "id": "1359944",
    "category": "Oncologist"
  },
  {
    "id": "1361",
    "category": "Oncology"
  },
  {
    "id": "1135546",
    "category": "Genetic Oncology"
  },
  {
    "id": "1135548",
    "category": "Medical Oncology"
  },
  {
    "id": "1135544",
    "category": "Radiation Oncology"
  },
  {
    "id": "1135545",
    "category": "Surgical Oncology"
  },
  {
    "id": "20000015",
    "category": "Ophthalmic Pathology"
  },
  {
    "id": "20000016",
    "category": "Ophthalmic Surgery"
  },
  {
    "id": "20000034",
    "category": "Oral and Maxillofacial Surgery"
  },
  {
    "id": "1505907",
    "category": "Orthodontics and Dentofacial Orthopedics"
  },
  {
    "id": "20000035",
    "category": "Orthopedic Surgery"
  },
  {
    "id": "20000036",
    "category": "Orthopedic Trauma"
  },
  {
    "id": "1362",
    "category": "Orthopedics"
  },
  {
    "id": "1120594",
    "category": "Otolaryngology"
  },
  {
    "id": "1359927",
    "category": "Pathologist"
  },
  {
    "id": "1364",
    "category": "Pathology"
  },
  {
    "id": "1135550",
    "category": "Clinical Pathology"
  },
  {
    "id": "1135551",
    "category": "Pulmonary Pathology"
  },
  {
    "id": "1135553",
    "category": "Surgical Pathology"
  },
  {
    "id": "20000017",
    "category": "Pediatric & Adolescent Gynecology"
  },
  {
    "id": "20000018",
    "category": "Pediatric Allergy and Immunology"
  },
  {
    "id": "1505908",
    "category": "Pediatric Cardiovascular Disease"
  },
  {
    "id": "20000019",
    "category": "Pediatric Cardiovascular Surgery"
  },
  {
    "id": "20000020",
    "category": "Pediatric Hematology-Oncology"
  },
  {
    "id": "1505909",
    "category": "Pediatric Medical Toxicology"
  },
  {
    "id": "1505910",
    "category": "Pediatric Neuro-Oncology"
  },
  {
    "id": "20000021",
    "category": "Pediatric Neurosurgery"
  },
  {
    "id": "20000037",
    "category": "Pediatric Orthopedic Surgery"
  },
  {
    "id": "1505844",
    "category": "Pediatric Plastic Surgery"
  },
  {
    "id": "1505912",
    "category": "Pediatric Psychology"
  },
  {
    "id": "20000038",
    "category": "Pediatric Rehabilitation Medicine"
  },
  {
    "id": "20000039",
    "category": "Pediatric Sleep Medicine"
  },
  {
    "id": "1505913",
    "category": "Pediatric Surgical Oncology"
  },
  {
    "id": "1505914",
    "category": "Pediatric Transplant Hepatology"
  },
  {
    "id": "1505915",
    "category": "Pediatric Trauma Surgery"
  },
  {
    "id": "2168",
    "category": "Phlebology"
  },
  {
    "id": "1359750",
    "category": "Physiatrist"
  },
  {
    "id": "1120596",
    "category": "Physiatry"
  },
  {
    "id": "164",
    "category": "Plastic Surgery"
  },
  {
    "id": "20000022",
    "category": "Plastic and Reconstructive Surgery"
  },
  {
    "id": "1359761",
    "category": "Podiatrist"
  },
  {
    "id": "320",
    "category": "Podiatry"
  },
  {
    "id": "2182",
    "category": "Preventive Medicine"
  },
  {
    "id": "1135520",
    "category": "Aerospace Medicine"
  },
  {
    "id": "1135521",
    "category": "Public Health"
  },
  {
    "id": "1359746",
    "category": "Proctologist"
  },
  {
    "id": "1366",
    "category": "Proctology"
  },
  {
    "id": "1505916",
    "category": "Psychiatrist"
  },
  {
    "id": "1505917",
    "category": "Psychologist"
  },
  {
    "id": "1367",
    "category": "Pulmonology"
  },
  {
    "id": "1344572",
    "category": "Reconstructive Surgery"
  },
  {
    "id": "1505918",
    "category": "Reconstructive Urology"
  },
  {
    "id": "20000023",
    "category": "Rehabilitation and Physical Medicine"
  },
  {
    "id": "2161",
    "category": "Rheumatology"
  },
  {
    "id": "20000024",
    "category": "Shoulder & Elbow Surgery"
  },
  {
    "id": "20000040",
    "category": "Spine Surgery"
  },
  {
    "id": "2148",
    "category": "Surgery"
  },
  {
    "id": "20000025",
    "category": "Surgical Critical Care"
  },
  {
    "id": "1505919",
    "category": "Thoracic Medical Oncology"
  },
  {
    "id": "1120593",
    "category": "Thoracic Surgery"
  },
  {
    "id": "1341694",
    "category": "Toxicology"
  },
  {
    "id": "20000027",
    "category": "Transfusion Medicine"
  },
  {
    "id": "2185",
    "category": "Transplant Surgery"
  },
  {
    "id": "20000041",
    "category": "Trauma Surgery"
  },
  {
    "id": "20000028",
    "category": "Urogynecology"
  },
  {
    "id": "1505920",
    "category": "Urologic Oncology"
  },
  {
    "id": "1359716",
    "category": "Urologist"
  },
  {
    "id": "1371",
    "category": "Urology"
  },
  {
    "id": "2162",
    "category": "Vascular Surgery"
  },
  {
    "id": "20000042",
    "category": "Wound Care"
  },
  {
    "id": "175",
    "category": "Medical Treatments"
  },
  {
    "id": "1352",
    "category": "AIDS & HIV Medicine"
  },
  {
    "id": "2166",
    "category": "Botox"
  },
  {
    "id": "2223",
    "category": "Electrolysis"
  },
  {
    "id": "1316",
    "category": "Head Lice Treatment"
  },
  {
    "id": "1317",
    "category": "Male Enhancement"
  },
  {
    "id": "1318",
    "category": "Men's Health Clinic"
  },
  {
    "id": "1120597",
    "category": "Occupational Medicine"
  },
  {
    "id": "1368",
    "category": "Sleep Medicine"
  },
  {
    "id": "2260",
    "category": "Vaccination"
  },
  {
    "id": "1329",
    "category": "Addiction Treatment Center"
  },
  {
    "id": "1319",
    "category": "Alzheimer's Care"
  },
  {
    "id": "1320",
    "category": "Anger Management"
  },
  {
    "id": "1321",
    "category": "Depression Therapy"
  },
  {
    "id": "1322",
    "category": "Life Coach"
  },
  {
    "id": "1323",
    "category": "Mental Health Clinic"
  },
  {
    "id": "1061245",
    "category": "Mental Health Counselor"
  },
  {
    "id": "1437885",
    "category": "Mental Health Service"
  },
  {
    "id": "1324",
    "category": "Occupational Therapy"
  },
  {
    "id": "1326",
    "category": "Psychiatry"
  },
  {
    "id": "1135533",
    "category": "Addiction Psychiatry"
  },
  {
    "id": "1135534",
    "category": "Forensic Psychiatry"
  },
  {
    "id": "1135535",
    "category": "Geriatric Psychiatry"
  },
  {
    "id": "1135536",
    "category": "Neuropsychiatry"
  },
  {
    "id": "2183",
    "category": "Psychoanalysis"
  },
  {
    "id": "1327",
    "category": "Psychology"
  },
  {
    "id": "1061246",
    "category": "Psychotherapy"
  },
  {
    "id": "1328",
    "category": "Smoking Cessation Program"
  },
  {
    "id": "2173",
    "category": "Speech Pathology"
  },
  {
    "id": "2116",
    "category": "Speech Therapy"
  },
  {
    "id": "177",
    "category": "Nutrition & Weight Loss"
  },
  {
    "id": "2167",
    "category": "Bariatric Medicine"
  },
  {
    "id": "1330",
    "category": "Nutritionist"
  },
  {
    "id": "1330094",
    "category": "Vitamin & Supplements Store"
  },
  {
    "id": "1331",
    "category": "Weight Loss Center"
  },
  {
    "id": "1135568",
    "category": "Pediatric Allergist"
  },
  {
    "id": "1135569",
    "category": "Pediatric Anesthesiology"
  },
  {
    "id": "1135570",
    "category": "Pediatric Cardiology"
  },
  {
    "id": "1135571",
    "category": "Pediatric Chiropractor"
  },
  {
    "id": "1135572",
    "category": "Pediatric Critical Care Medicine"
  },
  {
    "id": "1135573",
    "category": "Pediatric Emergency Medicine"
  },
  {
    "id": "1135574",
    "category": "Pediatric Gastroenterology"
  },
  {
    "id": "1135576",
    "category": "Pediatric Hematology"
  },
  {
    "id": "1135575",
    "category": "Pediatric Infectious Disease Medicine"
  },
  {
    "id": "1135566",
    "category": "Pediatric Internal Medicine"
  },
  {
    "id": "1135565",
    "category": "Pediatric Nephrology"
  },
  {
    "id": "1135561",
    "category": "Pediatric Neurology"
  },
  {
    "id": "1135559",
    "category": "Pediatric Oncology"
  },
  {
    "id": "1135556",
    "category": "Pediatric Ophthalmology"
  },
  {
    "id": "1135554",
    "category": "Pediatric Orthopedics"
  },
  {
    "id": "1135552",
    "category": "Pediatric Otolaryngology"
  },
  {
    "id": "1135549",
    "category": "Pediatric Pathology"
  },
  {
    "id": "1135531",
    "category": "Pediatric Physiatry"
  },
  {
    "id": "1135567",
    "category": "Pediatric Psychiatry"
  },
  {
    "id": "1135547",
    "category": "Pediatric Pulmonology"
  },
  {
    "id": "1135538",
    "category": "Pediatric Radiology"
  },
  {
    "id": "1135527",
    "category": "Pediatric Rheumatology"
  },
  {
    "id": "1135522",
    "category": "Pediatric Sports Medicine"
  },
  {
    "id": "1135529",
    "category": "Pediatric Surgery"
  },
  {
    "id": "1135525",
    "category": "Pediatric Urology"
  },
  {
    "id": "178",
    "category": "Pharmacy"
  },
  {
    "id": "1333",
    "category": "Patent & Proprietary Medicine"
  },
  {
    "id": "1334",
    "category": "Pharmacies"
  },
  {
    "id": "1335",
    "category": "Prescription Drugs"
  },
  {
    "id": "179",
    "category": "Physical Therapy"
  },
  {
    "id": "1336",
    "category": "Intensive Suit Therapy"
  },
  {
    "id": "1337",
    "category": "Massage Therapy"
  },
  {
    "id": "1338",
    "category": "Physical Therapist"
  },
  {
    "id": "1339",
    "category": "Sports Medicine"
  },
  {
    "id": "1135523",
    "category": "Abdominal Imaging"
  },
  {
    "id": "1135524",
    "category": "Diagnostic Radiology"
  },
  {
    "id": "1135526",
    "category": "Mammography"
  },
  {
    "id": "1135528",
    "category": "Musculoskeletal Radiology"
  },
  {
    "id": "1135530",
    "category": "Neuroradiology"
  },
  {
    "id": "1135532",
    "category": "Nuclear Radiology"
  },
  {
    "id": "1351",
    "category": "Prenatal Ultrasound Imaging"
  },
  {
    "id": "1312",
    "category": "Radiology"
  },
  {
    "id": "1315",
    "category": "X Rays & Imaging"
  },
  {
    "id": "1348",
    "category": "Doula"
  },
  {
    "id": "1349",
    "category": "Fertility Clinic"
  },
  {
    "id": "1366244",
    "category": "Gynecology"
  },
  {
    "id": "1350",
    "category": "Obstetrics"
  },
  {
    "id": "1829",
    "category": "Gift Shop"
  }
];


export function specialtyMappings(profile) {
  let specialties = [];
  if (!profile.categoryIds) {
    return null;
  }
  for (let id of profile.categoryIds) {
    let obj = mappings.find(o => o.id === id);
    if (obj) {
      specialties.push(obj.category)
    }
  }
  return specialties;
}
