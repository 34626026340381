import {
  address,
  phoneLink,
  phoneDisplay,
  nationalizedPhoneDisplay,
  emailLink,
  // getDirectionsUrl,
  toLocalizedDistance,
  toKilometers,
  toMiles,
  isTodayHoliday,
  bigDate,
  betterTime,
  dateRange,
  snakeToTitle,
  prettyPrintObject,
  joinList,
  image,
  truncate,
  openStatus,
  hoursList,
  generateCTAFieldTypeLink
} from './formatters-internal.js';

function getDirectionsUrl(profile, key = 'address') {
  const addr = profile[key];
  if (!addr) {
    return '';
  }

  const line2 = addr.line2 ? ` ${addr.line2},` : ``;
  const region = addr.region ? ` ${addr.region}` : ``;
  const rawQuery = `${addr.line1},${line2} ${addr.city},${region} ${addr.postalCode} ${addr.countryCode}`;
  const query = encodeURIComponent(rawQuery);

  let url = `https://www.google.com/maps/search/?api=1&query=${query}&output=classic`;

  if (profile.googlePlaceId) {
    url += `&query_place_id=${profile.googlePlaceId}`;
  }

  return url;
}

import {
  specialtyMappings
} from './category-mapping.js'

/**
 * Contains some of the commonly used formatters for parsing pieces of profile
 * information. To remove a formatter from the bundle, comment the desired
 * line below.
 */
const Formatters = {
  address,
  phoneLink,
  phoneDisplay,
  nationalizedPhoneDisplay,
  emailLink,
  getDirectionsUrl,
  toLocalizedDistance,
  toKilometers,
  toMiles,
  isTodayHoliday,
  bigDate,
  betterTime,
  dateRange,
  snakeToTitle,
  prettyPrintObject,
  joinList,
  image,
  truncate,
  openStatus,
  hoursList,
  generateCTAFieldTypeLink,
  specialtyMappings
};

export { Formatters as default };
